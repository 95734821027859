import moment from "moment";

const numberNotEmpty = value =>
  value !== null && value !== undefined && !isNaN(value);

const toPercentage = value =>
  numberNotEmpty(value) ? `${(value * 100).toFixed(2)}` : null;

const percentSignFormatter = (value, decimal = 2) =>
  numberNotEmpty(value) ? `${value.toFixed(decimal)}%` : null;

const strings = {
  date: date => moment(date).format("MMM Do YYYY"),
  price: price =>
    numberNotEmpty(price) ? `$${strings.priceFormat(price)}` : null,
  priceDecimal: price =>
    numberNotEmpty(price) ? `$${strings.priceFormat(price, 2)}` : null,
  tax: tax => {
    if (!!tax) return `$${strings.priceFormat(tax)} Taxes`;
    return null;
  },
  pricePerSize: psf => {
    if (!!psf) {
      if(isInt(psf) || psf > 100){
        return `${strings.priceFormat(psf)} PSF`;
      }
      else if(isFloat(psf)){
        return `${strings.priceDecimal(psf)} PSF`;
      }
    }
    return null;
  },
  annualPsf: psf => {
    if (!!psf) return `$${strings.priceFormat(psf)} PSF`;
    return null;
  },
  size: size => {
    if (!!size) return `${strings.priceFormat(size)} ft²`;
    return null;
  },
  grossSf: grosssf => {
    if (!!grosssf) return `${strings.priceFormat(grosssf)} gross ft²`;
    return null;
  },
  bldgDepth: value => (numberNotEmpty(value) ? `${value} ft²` : null),
  bldgFront: value => (numberNotEmpty(value) ? `${value} ft²` : null),
  decimal: value => (numberNotEmpty(value) ? value.toFixed(2) : null),
  percentToDecimal: val => (val / 100).toFixed(2),
  decimalToPercent: val => (val * 100).toFixed(0),
  percentToDecimalDynamicDecimal: (value) => numberNotEmpty(value) ? parseFloat((value / 100).toFixed(4)) : null,
  decimalToPercentDynamicDecimal: (value) => numberNotEmpty(value) ? parseFloat((value * 100).toFixed(4)) : null,
  maintenance: maintenance => {
    if (!!maintenance)
      return `$${strings.priceFormat(maintenance)} Maintenance`;
    return null;
  },
  ownership: ownership => {
    if (!!ownership) return strings.capitalizeFirstLetter(ownership);
    return null;
  },
  fee: fee => {
    return !!fee ? null : "No Fee";
  },
  furnished: furnished => {
    return !!furnished ? "Furnished" : "Unfurnished";
  },
  daysOnMarket: dom => {
    if (!!dom) return `${dom.toFixed(0)} DOM`;
    return null;
  },
  lobby: lobby => {
    switch (lobby) {
      case "fullTime":
      case "partTime":
        return "Lobby attendance";
      case "unattended":
        return "Lobby unattended";
      default:
        return null;
    }
  },
  isNewDevelopment: isNewDevelopment => {
    return !!isNewDevelopment ? "New Development" : null;
  },
  beds: (beds, isHouse) => {
    if (beds === null || isNaN(beds)) {
      return null;
    }

    switch (beds) {
      case 0:
        if (isHouse) return null;
        return "Studio";
      case 1:
        return `${beds} Bed`;
      default:
        return `${beds} Beds`;
    }
  },
  units: units => {
    if (units === null || isNaN(units) || units === 0) return null;

    switch (units) {
      case 1:
        return `Single Unit`;
      default:
        return `${units} units`;
    }
  },
  floors: floors => {
    if (floors === null || isNaN(floors)) return null;

    switch (floors) {
      case 0:
      case 1:
        return `Single Floor`;
      default:
        return `${floors} floors`;
    }
  },
  floor: floor => {
    if (floor === null || isNaN(floor)) return null;

    switch (floor) {
      case 0:
      case 1:
        return `Ground floor`;
      default:
        return `${strings.ordinal_suffix_of(floor)} floor`;
    }
  },
  rooms: rooms => {
    if (rooms === null || isNaN(rooms)) {
      return null;
    }

    switch (parseInt(rooms, 10)) {
      case 0:
        return "Studio";
      case 1:
        return `${rooms} Room`;
      default:
        return `${rooms} Rooms`;
    }
  },
  baths: (baths, isHouse) => {
    if (baths === null || isNaN(baths)) {
      return;
    }

    switch (parseFloat(baths)) {
      case 0:
        if (isHouse) return null;
        return "No baths";
      case 1:
        return `${baths} Bath`;
      default:
        return `${baths} Baths`;
    }
  },
  houseType: houseType => {
    return strings.toReadableForm(houseType);
  },
  toCamelCase: str => {
    return str
      .replace(/(\s+)(.)/g, function(all, $1, $2) {
        return $2.toUpperCase();
      })
      .replace(/^(.)/, function($1) {
        return $1.toLowerCase();
      });
  },
  capitalizeFirstLetter: str => {
    return !!str
      ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
      : null;
  },
  toReadableForm: str => {
    return !str
      ? null
      : str
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, function(str) {
            return str.toUpperCase();
          })
          .replace("  ", " ");
  },
  ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  },
  toCurrency: value => {
    if (!value || value === 0) return;
    let nf = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return nf.format(value);
  },
  numberNotEmpty,
  toPercentage,
  percentSignFormatter,
  toPercentageAndFormat: (value, decimal = 2) => {
    const converted = toPercentage(value);
    if (!converted) return null;
    return percentSignFormatter(+converted, decimal);
  },
  priceFormat: (num, digits) => {
    try {
      if (!num) return 0;

      num = parseFloat(num);

      let si = [
          { value: 1e9, denominator: 1e9, symbol: "B", digits: 3 },
          { value: 1e6, denominator: 1e6, symbol: "M", digits: 3 },
          { value: 1e5, denominator: 1e3, symbol: "K", digits: 0 }
        ],
        rx = /\.0+$|(\.[0-9]*[1-9])0+$/,
        i;
      for (i = 0; i < si.length; i++) {
        if (Math.abs(num) > si[i].value) {
          if (
            (num / si[i].denominator).toFixed(digits || si[i].digits) === "1000"
          ) {
            return (
              (num / si[i - 1].denominator)
                .toFixed(digits || si[i - 1].digits)
                .replace(rx, "$1") + si[i - 1].symbol
            );
          }
          return (
            (num / si[i].denominator)
              .toFixed(digits || si[i].digits)
              .replace(rx, "$1") + si[i].symbol
          );
        }
        if (Math.abs(num) === si[i].value) {
          return (
            (num / si[i].denominator).toFixed(digits || 0).replace(rx, "$1") +
            si[i].symbol
          );
        }
      }
      return num
        .toFixed(digits || 0)
        .replace(rx, "$1")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (err) {
      return num;
    }
  },
  formatAmenity: amenities => {
    let result = "";
    for (let [key, value] of Object.entries(amenities)) {
      const amenityName = key;
      const temp = `${
        typeof value === "boolean"
          ? value
            ? `${amenityName}`
            : ""
          : `${amenityName} ${value}`
      }`;
      result += temp + "\n";
    }
    const formatted = result.split("\n").filter(i => Boolean(i))
    return formatted;
  }
};

function isInt(n){
  return Number(n) === n && n % 1 === 0;
}

function isFloat(n){
  return Number(n) === n && n % 1 !== 0;
}

export default strings;
