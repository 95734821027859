import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import { ApolloProvider } from "react-apollo";
import AWSAppSyncClient, { createAppSyncLink } from "aws-appsync";
import { AUTH_TYPE } from "aws-appsync/lib/link/auth-link";
import * as localForage from "localforage";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Moment from "react-moment";
import CheckNewVersion from "components/check-new-version";
import Snackbar, { SnackbarProvider } from "components/snackbar";
import App from "./App";
import config from "./config";
import muiTheme from "./mui-theme";
import * as serviceWorker from "./serviceWorker";

import { setContext } from "apollo-link-context";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import "./index.css";
const packageJson = require('../package.json');

const generateClassName = createGenerateClassName({
  productionPrefix: 'compit_',
  disableGlobal: true
});

if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}


Moment.globalFormat = "MMM D YYYY";
const {
  cognito: { region, userPoolId, identityPoolId, userPoolWebClientId },
  graphql: { apiKey, endpoint },
  api: { queryExport: { endpoint: queryExportEndpoint } }
} = config;

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "QueryExport",
        endpoint: queryExportEndpoint
      }
    ]
  },
  Auth: {
    mandatorySignIn: true,
    region,
    userPoolId,
    identityPoolId,
    userPoolWebClientId
  },
});

const AppSyncConfig = {
  url: endpoint,
  region: "us-east-1",
  auth: {
    apiKey: apiKey,
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      try {
        return (await Auth.currentSession()).getIdToken().getJwtToken()
      }
      catch (err) {
        console.log(err);
        return "";
      }
    }
  },
  disableOffline: true
};

const client = new AWSAppSyncClient(AppSyncConfig, {
  link: createAppSyncLink({
    ...AppSyncConfig,
    resultsFetcherLink: ApolloLink.from([
      setContext((request, previousContext) => ({
        headers: {
          ...previousContext.headers,
          "X-Application-Version": packageJson.version
        }
      })),
      createHttpLink({
        uri: AppSyncConfig.url
      })
    ])
  })
});

const theme = createMuiTheme(muiTheme);

const render = Component => {
  return ReactDOM.render(
    <StylesProvider generateClassName={generateClassName}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <CheckNewVersion />
          <Router>
            <Component />
          </Router>
          <Snackbar />
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
    </StylesProvider>,
    document.getElementById('root')
  );
};

render(App);

if (window.performance) {
  if (performance.navigation.type === 1) {
    localForage.dropInstance().then(function() {
      console.log('Dropped the store of the current instance');
    });
  }
}

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    console.log("Hot in here")
    render(NextApp);
  });
}




if ('serviceWorker' in navigator) {
  // When the user asks to refresh the UI, we'll need to reload the window
  navigator.serviceWorker.addEventListener('controllerchange', function (event) {
    window.location.reload();
  });
  serviceWorker.register();
}
