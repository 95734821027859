import React, { createContext, useContext, useReducer } from "react";
import listingsStateReducer, {
  initialState,
  actionTypes as listingActionTypes
} from "../components/listings-view/listings-reducer";

const StateContext = createContext();

export const StateProvider = ({ children }) => (
  <StateContext.Provider
    value={useReducer(listingsStateReducer, null, initialState)}
  >
    {children}
  </StateContext.Provider>
);

export const actionTypes = listingActionTypes;
export const useStateValue = () => useContext(StateContext);