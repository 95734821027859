import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "components/not-found";
import Spinner from '../spinner';

import AuthenticatedRoute from "components/routes/authenticated-route";
import UnauthenticatedRoute from "components/routes/unauthenticated-route";

const SavedSearches = lazy(() => import("components/saved-searches"));
const Login = lazy(() => import("components/login"));
const Signup = lazy(() => import("components/signup"));
const Dashboard = lazy(() => import("components/dashboard"));
const NhdStats = lazy(() => import("components/nhd-stats"));
const AddTag = lazy(() => import("components/add-tag"));
const OnePager = lazy(() => import("components/one-pager"));

export default ({ childProps }) => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <AuthenticatedRoute
        path="/"
        exact
        component={Dashboard}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/tag/:action/:id"
        component={AddTag}
        props={childProps}
      />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        props={childProps}
      />
      <UnauthenticatedRoute
        path="/signup"
        exact
        component={Signup}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/stats/:neighborhoodName"
        component={NhdStats}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/guest/one-pager/:onePagerId"
        component={OnePager}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/one-pager/:entityId"
        component={OnePager}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/saved-searches"
        component={SavedSearches}
        props={childProps}
      />
      <Route component={NotFound} />
    </Switch>
  </Suspense>
);
