import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";
import * as _ from "lodash";

const MenuButton = (props) => {
  const [anchorEl, setAnchorEl] = useState(false);

  const handleToggle = () => {
    setAnchorEl((prevOpen) => !prevOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const listItems = _.map(props.items, (link, i) => {
    return (<MenuItem onClick={(e) => {
      e.preventDefault();
      handleToggle();
      link.onClick();
    }} key={i} >{link.displayName}</MenuItem>);
  });

  return (
    <div>
      <IconButton
        aria-controls={props.name}
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        {props.icon}
      </IconButton>
      <Menu
        id={props.name}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleToggle}
      >
        {listItems}
      </Menu>
    </div>
  );

}

export default MenuButton;