import React, {
  memo,
  useContext,
  createContext,
  useCallback,
  useState
} from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";

const VERTICAL = "bottom";
const HORIZONTAL = "center";

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  default: {},
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center"
  },
  content: {
    display: "flex",
    alignItems: "center",
    marginLeft: "28px"
  },
  margin: {
    margin: theme.spacing(1)
  }
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  default: InfoIcon
};

const types = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
  default: "default"
};

const SnackbarContext = createContext({});
export const SnackbarConsumer = SnackbarContext.Consumer;

const defaultState = {
  message: "",
  isOpen: false,
  type: types.default,
  content: null,
  autoHide: true
};

export function SnackbarProvider({ children }) {
  const [state, setState] = useState(defaultState);

  const handleClose = useCallback((event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...defaultState, isOpen: false });
  }, []);
  const show = useCallback(
    ({ message, type = types.default, content }) =>
      setState({ isOpen: true, message, type, content }),
    []
  );

  const value = {
    state,
    handleClose,
    show,
    types
  };
  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
}

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context)
    throw new Error(
      `'useSnackbar' has to be a descendant of 'SnackbarProvider'`
    );
  return context;
};

function SnackbarComponent() {
  const {
    state: { isOpen, message = "", type = types.default, content, autoHide },
    handleClose
  } = useSnackbar();
  const classes = useStyles();
  const Icon = variantIcon[type];
  const autoHideProps = {};
  if (autoHide) {
    autoHideProps.autoHideDuration = 5000;
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: VERTICAL, horizontal: HORIZONTAL }}
      key="snackbar"
      open={isOpen}
      onClose={handleClose}
      variant={type}
      {...autoHideProps}
      ContentProps={{ "aria-describedby": "message-id" }}
    >
      <SnackbarContent
        className={clsx(classes[type], classes.margin)}
        aria-describedby="client-snackbar"
        message={
          <>
            {message && (
              <span id="client-snackbar" className={classes.message}>
                <Icon className={clsx(classes.icon, classes.iconVariant)} />
                {message}
              </span>
            )}
            {content && <div className={classes.content}>{content}</div>}
          </>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
}

export default memo(SnackbarComponent);
