export default {
  typography: {
    body1: {
      fontWeight: 400,
      fontSize: "1.2rem"
    },
    color: "rgba(0, 0, 0, 0.54)"
  },
  text: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12
      }
    },
    MuiTableCell: {
      root: {
        padding: "4px 20px 4px 20px"
      }
    },
    MuiBadge: {
      badge: {
        fontSize: "10px",
        minWidth: "15px",
        height: "15px"
      }
    },
    MuiCardHeader: {
      title: {
        fontSize: "12px"
      },
      subheader: {
        fontSize: "12px"
      }
    }
  },
  palette: {
    primary: {
      main: "#4f8edc",
      secondary: "#4dabf5",
      contrastText: "#fff",
      dark: "#1769aa",
      light: "#4dabf5"
    },
    background: {
      default: "#efefef",
      paper: "#ffffff"
    }
  }
};
