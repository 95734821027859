import _ from "lodash";

const envConfig = {
  production: {
    graphql: {
      endpoint: "https://prod.invest-api.compit.com/graphql"
    },
    api: {
      queryExport: {
        endpoint:
          "https://p3pwntidjk.execute-api.us-east-1.amazonaws.com/production/query/export"
      }
    }
  },
  staging: {
    graphql: {
      endpoint: "https://dev.invest-api.compit.com/graphql"
    },
    api: {
      queryExport: {
        endpoint:
          "https://8q6imtib8k.execute-api.us-east-1.amazonaws.com/dev/query/export"
      }
    }
  },
  local: {
    graphql: {
      endpoint: "http://localhost:4000/graphql"
    },
    api: {
      queryExport: {
        endpoint: "http://localhost:4000/query/export"
      }
    }
  }
};

const baseConfig = {
  cognito: {
    region: "us-east-1",
    userPoolId: "us-east-1_hEK16R3Rq",
    userPoolWebClientId: "7k2fvl8tecqbtg9egda9bkinim",
    identityPoolId: "us-east-1:0a7827df-2343-47c7-8d6d-ca9cdb11d837",
    federated: {
      google_client_id:
        "20230076703-2vlosqitqesfqk80muiam7g28ka5u6u9.apps.googleusercontent.com"
    },
    ulooshId: "d56621af-1e00-43b6-98a8-76cce7814b45",
    ariId: "959fdc99-b741-47ee-a0a8-b39e77cb8afc",
  },
  proformas: {
    uloosh: {
      webApp: "https://script.google.com/macros/s/AKfycbxTEqmlVYdQqwa0-NO-LLY73qc6ZandN_vxgtCF-XafL6gb223MrloVCKwvBxDbk4a8Yw/exec",
      sheet: "https://docs.google.com/spreadsheets/d/1Nrev_5oN414hF_wsi2oftl4J3x29f9czD9LaNOTgFKA/edit#gid=0"
    },
    ari: {
      webApp: "https://script.google.com/macros/s/AKfycbw4_hdePhkAPU3YthfwHTAETBexQTjIw9jYGg-P8NlNzwOLR6ylG4k5tItT5mKMGnM1/exec",
      sheet: "https://docs.google.com/spreadsheets/d/1uVc_A3V-TeuJkUv6Um0yRJtLiYc7xolc6QVJZpF8n80/edit#gid=0"
    },
    test: {
      webApp: "https://script.google.com/macros/s/AKfycbye8cr6ys2kh7-jy6XKgi30jVTXby4IgM_xZe0C1jvDsct7LZP7CmOTDJnf53iSfxwi/exec",
      sheet: "https://docs.google.com/spreadsheets/d/1fqdzu_5uFg_7FW6oUzHtiB-s1r6cNL6OmRu-kgxRKyU/edit#gid=0"
    },
  },
  graphql: {
    apiKey: "SWmfhzDdFb2WZMInjOuCj2rMJqZCejaU7DYFzVUb",
    endpoint: "https://dev.invest-api.compit.com/graphql",
    cache: process.env.REACT_APP_STAGE === "local"
  },
  design: {
    imagePlaceHolder: "https://media.compit.com/image-placeholder.png",
    cloudinaryBaseUrl:
      "https://res.cloudinary.com/compit-inc/image/fetch"
  }
};

const stage = process.env.REACT_APP_STAGE || "local";
const currEnvConfig = stage in envConfig ? envConfig[stage] : {};

const merged = _.merge(baseConfig, currEnvConfig);

export default merged;
