import metrics, { aggsDictionary } from '../../field-mappings/marketAnalysis';
import _ from 'lodash';

const convertHistogramToGroupedMetrics = histogram => {
  const metricsMap = new Map([]);
  const columnsSet = new Set([]);
  histogram.forEach(({key_as_string, key, doc_count,  ...rest}) => {
    columnsSet.add(key_as_string);
    Object.entries(rest).forEach(([key, obj]) => {
      const value = obj.hasOwnProperty('value') ? _.get(obj, 'value') : _.get(obj, 'values[50.0]'); // Average or Percentiles
      const genericMetricKey = key.includes('_pct') ? "pct" : key.includes('_lag') ? "lag" : key.includes('_doc_count') ? "count" : "value"
      const metricGroupKey = key.replace('_lag_pct', '').replace('_lag', '').replace('_doc_count', '') //todo: MUST CHANGE THIS BEFORE RELEASE. Also using _count etc may remove fields like xxxx_country
      const metricMap = metricsMap.has(metricGroupKey) ? metricsMap.get(metricGroupKey) : new Map([]);
      const metricByDate = metricMap.has(key_as_string) ? metricMap.get(key_as_string) : {};
      metricByDate[genericMetricKey] = value;
      metricMap.set(key_as_string, metricByDate);
      metricsMap.set(metricGroupKey, metricMap);
    })
  });
  return { metricsMap, columnsSet }
}

const convertMetricsMapToRows = ({metricsMap, aggregationType, columnsSet}) => {
  const rows = [];
  metricsMap.forEach((val, key) => {
    const metric = extractMetricMetaData(key)
    const overrideAggregationType = metric.aggregationType || aggregationType;
    
    const name = `${aggsDictionary[overrideAggregationType]} ${metric.label}`;
    let row = {
      name,
      aggregationType: overrideAggregationType, 
      formatter: metric.formatter,
      sourceField: metric.field,
      columns: [...columnsSet]
    }
    
    val.forEach((val, key) => {
      row[key] = val;
    })
    rows.push(row);
  })
  return rows;
}

const extractMetricMetaData = key => {
  const aggOverides = ['sum','value_count'];

  const aggIndicator = key.split('__')[0];
  const metric = key.split('__').pop().replace(/-dot-/g,'.');
  const findObject = { 'field': metric };
  if (aggOverides.includes(aggIndicator)) {
    findObject.aggregationType = aggIndicator;
  }
  const metricObj = _.find(metrics, findObject );
  return metricObj
  
};

const rankFormattedAggs = (formattedAggs, mergedColumnsSet) => {
  const metricNames = _.get(formattedAggs, '0.data', []).map(metric => metric.name);
  const columns = [...mergedColumnsSet];
  const ranksMap = new Map([])
  const orderedRanksMap = new Map([])

  const createRanksMap = () => {
    formattedAggs.forEach(({data}) => {
      metricNames.forEach(metricName => {
        const metricData = _.find(data, ['name', metricName])
        columns.forEach(column => {
          const value = _.get(metricData, `${column}.value`);
          if (_.isNumber(value)) {
            const ranksMapIdentifier = `${metricName}_${column}`;
            const valuesSet = ranksMap.has(ranksMapIdentifier) ? ranksMap.get(ranksMapIdentifier) : new Set();
            valuesSet.add(value)
            ranksMap.set(ranksMapIdentifier, valuesSet)
          }
        })
      })
    });
  }

  const orderRanksMap = () => {
    ranksMap.forEach((valuesSet, key) => {
      orderedRanksMap.set(key, [...valuesSet].sort((a, b) => b - a))
    })
  }

  const createRankedAggs = () => {
    const rankedAggs = formattedAggs.map(({data, ...rest}) => {
      data.forEach(metric => {
        const metricName = metric.name
        columns.forEach(column => {
          const metricValue = _.get(metric, [column, 'value']);
          if (_.isNumber(metricValue)){
            const ranksMapIdentifier = `${metricName}_${column}`;
            const valuesArray = orderedRanksMap.has(ranksMapIdentifier) ? orderedRanksMap.get(ranksMapIdentifier) : [];
            const metricRank = valuesArray.indexOf(metricValue) + 1;
            _.set(metric, `${column}.rank`, metricRank)
            _.set(metric, `${column}.ranks`, valuesArray.length)
          }
        })
      })
      return {
        data,
        ...rest
      }
    });
    return rankedAggs
  }

    createRanksMap()
    orderRanksMap()
    return createRankedAggs()
}

const extractAndRankHierarchicalAggs = ({
  aggregations,
  aggregationType
}) => {
  let mergedColumnsSet = new Set([]);
  let formattedAggs = [];
  formattedAggs.push(
    ...aggregations.buckets.map(({ key, doc_count, ...rest }, index) => {
      const result = { name: key };
      const histogram = _.get(rest, 'byDate.buckets')
      const {metricsMap, columnsSet} = convertHistogramToGroupedMetrics(histogram)
      result.columns = [...columnsSet];
      if (columnsSet.size > mergedColumnsSet.size) mergedColumnsSet = columnsSet;
      const rows = convertMetricsMapToRows({metricsMap, aggregationType, columnsSet})
      result.data = rows;
      return result;
    })
  );

  return {
    rankedAggs: rankFormattedAggs(formattedAggs, mergedColumnsSet), 
    mergedColumnsSet
  };
};

export default extractAndRankHierarchicalAggs;
