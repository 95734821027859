import startCase from "lodash/startCase";

const fields = [
  {
    field: "address.address",
    label: "Address",
    type: "String",
    isTextField: true,
    group: "Address",
    isGeo: true,
    isFilterable: false
  },
  {
    field: "unit.unit",
    label: "Unit",
    type: "String",
    isTextField: false,
    group: "Unit",
    isFilterable: true
  },
  {
    field: "score.score",
    label: "Score",
    type: "Int",
    group: "Score",
    isFilterable: true
  },
  {
    field: "score.score_percentile",
    label: "Score Percentile",
    type: "Float",
    group: "Score",
    isFilterable: true,
    isPercent: true,
  },
  {
    field: "building.ownership",
    label: "Ownership",
    type: "Options",
    isTextField: true,
    group: "Building",
    isFilterable: true
  },
  {
    field: "listing.status",
    label: "Status",
    type: "Options",
    isTextField: true,
    group: "Listing",
    isFilterable: true
  },
  {
    field: "unit.beds",
    label: "Beds",
    type: "Options",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "unit.baths",
    label: "Baths",
    type: "Options",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "unit.size",
    label: "Size",
    type: "Int",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "listing.pricePerSize",
    label: "PSF",
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.price",
    label: "Price",
    type: "Int",
    group: "Listing",
    isFilterable: true,
    prefix: "$"
  },
  {
    field: "listing.estimated_price",
    label: "Estiamted Price",
    type: "Float",
    group: "Unit",
    prefix: "$",
    isFilterable: true
  },
  {
    field: "deals.arbitrage.arbitrage",
    label: "Arbitrage",
    isPercent: true,
    type: "Float",
    group: "Arbitrage",
    isFilterable: true
  },
  {
    field: "unit.appreciation",
    label: "Potential Appreciation",
    isPercent: true,
    type: "Float",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "listing.discount_from_last_ask",
    label: "Negotiability",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.price_purchase_deviation",
    label: "Deviation from Last Subject Sale",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.listingDate",
    label: "Listing Date",
    type: "DateTime",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.daysOnMarket",
    label: "Days On Market",
    type: "Int",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.number_of_reductions",
    label: "Number Of Reductions",
    type: "Int",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.priceChangePct",
    label: "Price Change Pct",
    type: "Float",
    group: "Listing"
  },
  {
    field: "listing.last_reduction_date",
    label: "Last Reduction Date",
    type: "DateTime",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.tax",
    label: "Tax",
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.common_charges",
    label: "Common Charges",
    type: "Int",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.monthly",
    label: "Monthlies",
    type: "Int",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.monthlies_psf",
    label: "Monthlies Psf",
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "unit.estimated_rent",
    label: "Estimated Rent",
    type: "Float",
    group: "Income",
    isFilterable: true
  },
  {
    field: "deals.income.cap_rate",
    label: "Cap Rate",
    isPercent: true,
    type: "Float",
    group: "Income",
    isFilterable: true
  },
  {
    field: "deals.income.out_of_pocket",
    label: "Down Payment",
    type: "Float",
    group: "Break Even",
    isFilterable: true
  },
  {
    field: "deals.income.out_of_pocket_pct",
    label: "Down Payment %",
    isPercent: true,
    type: "Float",
    group: "Break Even",
    isFilterable: true
  },
  {
    field: "deals.income.cash_flow",
    label: "Cash Flow",
    type: "Int",
    group: "Break Even",
    isFilterable: true
  },
  {
    field: "deals.income.cash_on_cash",
    isPercent: true,
    label: "Cash On Cash %",
    type: "Float",
    group: "Break Even",
    isFilterable: true
  },
  {
    field: "unit.last_sale_in_line_date",
    label: "Last sale in line date",
    type: "DateTime",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "unit.last_pending_in_line_date",
    label: "Last pending in line date",
    type: "DateTime",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "unit.smartzipInfo",
    label: "Tagged by Smartzip",
    type: "Exists",
    group: "Unit",
    isFilterable: true,
  },
  {
    field: "unit.taggedByExpLiMO",
    label: "Tagged by ExpLiMO",
    type: "Exists",
    group: "Unit",
    isFilterable: true,
  },
  {
    field: "unit.taggedByFSBO",
    label: "Tagged by FSBO",
    type: "Exists",
    group: "Unit",
    isFilterable: true,
  },
  {
    field: "deals.flip.estimated_target_rent_income",
    label: "Compit Target Rent",
    type: "Float",
    group: "Income",
    isFilterable: true
  },
  {
    field: "deals.flip.eirr",
    label: "EIRR",
    isPercent: true,
    type: "Float",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "unit.hasOwnerInfo",
    label: "Has owner info",
    type: "Boolean",
    group: "Owner",
    isFilterable: true
  },
  {
    field: "unit.hasPhone",
    label: "Has Phone Number",
    type: "Boolean",
    group: "Owner",
    isFilterable: true
  },
  {
    field: "unit.hasEmail",
    label: "Has Email",
    type: "Boolean",
    group: "Owner",
    isFilterable: true
  },
  {
    field: "unit.previously_converted",
    label: "Previously Converted",
    type: "Boolean",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "unit.previously_converted_by_line",
    label: "Previously Converted",
    type: "Boolean",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "deals.flip.target_multiple",
    label: "Target Multiple",
    type: "Float",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "deals.flip.target_cap_rate",
    label: "Target Cap Rate",
    isPercent: true,
    type: "Float",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "building.BldgClass",
    label: "Bldg Class",
    type: "Options",
    isTextField: true,
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.BldgFront",
    label: "Bldg Front",
    type: "Float",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.BldgDepth",
    label: "Bldg Depth",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.unitsTotal",
    label: "Units Total",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "neighborhood.neighborhoodName",
    label: "Neighbourhood",
    type: "Tree",
    isTextField: true,
    isGeo: true,
    group: "Neighbourhood",
    isFilterable: true
  },
  {
    field: "borough.boroughName",
    label: "Borough Name",
    type: "Options",
    isTextField: true,
    group: "Borough",
    isGeo: true,
    isFilterable: true
  },
  {
    field: "deals.income.annual_rent_psf",
    label: "Annual Rent Psf",
    type: "Float",
    group: "Income",
    isFilterable: true
  },
  {
    field: "listing.bld_price_deviation",
    label: "Building Price Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.bld_psf_deviation",
    label: "Building Psf Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.bld_size_deviation",
    label: "Building Size Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.bld_estimated_price_deviation",
    label: "Building Estimated Price Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.bld_estimated_psf_deviation",
    label: "Building Estimated PSF Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.bld_estimated_rent_deviation",
    label: "Building Estimated Rent Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.bld_cap_rate_deviation",
    label: "Building Cap-rate Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.bld_estimated_monthlies_deviation",
    label: "Building Estimated Monthlies Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.nhd_price_deviation",
    label: "Neighbourhood Price Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.nhd_psf_deviation",
    label: "Neighbourhood Psf Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.nhd_size_deviation",
    label: "Neighbourhood Size Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.nhd_estimated_price_deviation",
    label: "Neighbourhood Estimated Price Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.nhd_estimated_psf_deviation",
    label: "Neighbourhood Estimated PSF Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.nhd_estimated_rent_deviation",
    label: "Neighbourhood Estimated Rent Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.nhd_cap_rate_deviation",
    label: "Neighbourhood Cap-rate Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.nhd_estimated_monthlies_deviation",
    label: "Neighbourhood Estimated Monthlies Deviation",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "deals.flip.bld_upper_price_deviation",
    label: "Building Upper Price Deviation",
    isPercent: true,
    type: "Float",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "deals.flip.bld_upper_psf_deviation",
    label: "Building Upper Psf Deviation",
    isPercent: true,
    type: "Float",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "deals.flip.bld_upper_size_deviation",
    label: "Building Upper Size Deviation",
    isPercent: true,
    type: "Float",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "deals.flip.convertible_bed_size",
    label: "Conversion potential",
    type: "Boolean",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "deals.investments_keywords",
    label: "Keywords",
    type: "String",
    group: "Flip",
    isFilterable: false
  },
  {
    field: "listing.investments_keyword",
    label: "Description Keywords",
    type: "String",
    isTextField: true,
    group: "Flip",
    isFilterable: true,
    size: 500
  },
  {
    field: "listing.discount_present",
    label: "Is Discount Present",
    type: "Boolean",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "deals.flip.nhd_upper_price_deviation",
    label: "Neighbourhood Upper Price Deviation",
    isPercent: true,
    type: "Float",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "deals.flip.nhd_upper_psf_deviation",
    label: "Neighbourhood Upper Psf Deviation",
    isPercent: true,
    type: "Float",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "deals.flip.nhd_upper_size_deviation",
    label: "Neighbourhood Upper Size Deviation",
    isPercent: true,
    type: "Float",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "deals.flip.target_confidence_level",
    label: "Target Confidence Level",
    type: "Int",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "deals.flip.target_sale_price",
    label: "Target Sale Price",
    type: "Float",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "deals.flip.upper_baths",
    label: "Upper Baths",
    type: "Options",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "deals.flip.upper_beds",
    label: "Upper Beds",
    type: "Options",
    group: "Flip",
    isFilterable: true
  },
  {
    field: "AssessTot",
    label: "Assess Tot",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.BldgArea",
    label: "Bldg Area",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.ComArea",
    label: "Com Area",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.CondoNo",
    label: "Condo No",
    type: "Int",
    group: "Building",
    isFilterable: false
  },
  {
    field: "building.LandUse",
    label: "Land Use",
    type: "String",
    isTextField: true,
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.LotArea",
    label: "Lot Area",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.LotDepth",
    label: "Lot Depth",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.LotFront",
    label: "Lot Front",
    type: "Float",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.NumFloors",
    label: "Num Floors",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.OfficeArea",
    label: "Office Area",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.OwnerType",
    label: "Owner Type",
    type: "String",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.ResArea",
    label: "Res Area",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.RetailArea",
    label: "Retail Area",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.StrgeArea",
    label: "Strge Area",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.UnitsRes",
    label: "Units Res",
    type: "Int",
    group: "Building",
    isFilterable: false
  },
  {
    field: "address.alternates",
    label: "Address",
    type: "String",
    isTextField: true,
    group: "Address",
    isGeo: true,
    isFilterable: true
  },
  {
    field: "listing.agent1Name",
    label: "Agent1 Name",
    type: "String",
    isTextField: true,
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.agent1email",
    label: "Agent1email",
    type: "String",
    isTextField: true,
    group: "Listing",
    isFilterable: false
  },
  {
    field: "listing.agent1phone",
    label: "Agent1phone",
    type: "String",
    isTextField: true,
    group: "Listing",
    isFilterable: false
  },
  {
    field: "listing.agent1pic",
    label: "Agent1pic",
    type: "String",
    isTextField: true,
    group: "Listing",
    isFilterable: false
  },
  {
    field: "listing.agent2Name",
    label: "Agent2 Name",
    type: "String",
    isTextField: true,
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.agent2email",
    label: "Agent2email",
    type: "String",
    isTextField: true,
    group: "Listing",
    isFilterable: false
  },
  {
    field: "listing.agent2phone",
    label: "Agent2phone",
    type: "String",
    isTextField: true,
    group: "Listing",
    isFilterable: false
  },
  {
    field: "listing.agent2pic",
    label: "Agent2pic",
    type: "String",
    isTextField: true,
    group: "Listing",
    isFilterable: false
  },
  {
    field: "unit.airConditioning",
    label: "Air Conditioning",
    type: "Boolean",
    group: "Unit",
    isFilterable: false
  },
  {
    field: "deals.income.annualPsf",
    label: "Annual Psf",
    type: "Float",
    group: "Income",
    isFilterable: true
  },
  {
    field: "unit.apartmentType",
    label: "Apartment Type",
    type: "Options",
    isTextField: true,
    group: "Unit",
    isFilterable: true
  },
  {
    field: "listing.available",
    label: "Available",
    type: "Int",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "address.block",
    label: "Block",
    type: "Int",
    group: "Address",
    isGeo: true,
    isFilterable: true
  },
  {
    field: "building.buildingClass",
    label: "Building Class",
    type: "String",
    isTextField: true,
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.buildingOutdoor",
    label: "Building Outdoor",
    type: "Options",
    formatter: startCase,
    isTextField: true,
    group: "Building",
    isFilterable: true
  },
  {
    field: "city.city",
    label: "City",
    type: "Options",
    isTextField: true,
    group: "City",
    isGeo: true,
    isFilterable: false
  },
  {
    field: "unit.cityView",
    label: "City View",
    type: "Boolean",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "building.classCategory",
    label: "Class Category",
    type: "String",
    isTextField: true,
    group: "Building",
    isFilterable: true
  },
  {
    field: "listing.companyName",
    label: "Company Name",
    type: "String",
    isTextField: true,
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.description",
    label: "Description",
    type: "String",
    isTextField: true,
    group: "Listing",
    isFilterable: false
  },
  {
    field: "building.elevator",
    label: "Elevator",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "listing.fee",
    label: "Fee",
    type: "Boolean",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.finance",
    label: "Finance",
    type: "Int",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "unit.fireplace",
    label: "Fireplace",
    type: "Boolean",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "unit.floor",
    label: "Floor",
    type: "Int",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "unit.floorType",
    label: "Floor Type",
    type: "Options",
    isTextField: true,
    group: "Unit",
    isFilterable: true
  },
  {
    field: "building.floors",
    label: "Floors",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "listing.furnished",
    label: "Furnished",
    type: "Boolean",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "building.garage",
    label: "Garage",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.grosssf",
    label: "Gross Sqft",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.gym",
    label: "Gym",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "listing.hasImages",
    label: "Has Images",
    type: "Boolean",
    group: "Listing",
    isFilterable: false
  },
  {
    field: "building.highRise",
    label: "High Rise",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "address.houseNumber",
    label: "House Number",
    type: "Int",
    group: "Address",
    isGeo: true,
    isFilterable: true
  },
  {
    field: "building.houseType",
    label: "House Type",
    type: "Options",
    formatter: startCase,
    isTextField: true,
    group: "Building",
    isFilterable: true
  },
  {
    field: "unit.isHouse",
    label: "Is House",
    type: "Boolean",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "listing.isLatest",
    label: "Is Latest",
    type: "Boolean",
    group: "Listing",
    isFilterable: false
  },
  {
    field: "building.isLobbyAttended",
    label: "Is Lobby Attended",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.isNewDevelopment",
    label: "Is New Development",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "listing.isShortTerm",
    label: "Is Short Term",
    type: "Boolean",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "building.landsf",
    label: "Land Sqft",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.laundry",
    label: "Laundry",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "unit.line",
    label: "Line",
    type: "String",
    isTextField: true,
    group: "Unit",
    isFilterable: false
  },
  {
    field: "building.loft",
    label: "Loft",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.lot",
    label: "Lot",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "listing.maintenance",
    label: "Maintenance",
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.estimated_maintenance",
    label: "Est. Maintenance",
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.estimated_taxes",
    label: "Est. Taxes",
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.estimated_common_charges",
    label: "Est. Common Charges",
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.estimated_monthlies",
    label: "Est. Monthlies",
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.estimated_monthlies_psf",
    label: "Est. Monthlies psf",
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.dev_from_last_sold",
    label: "Deviation from last sold in line",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.dev_from_max_sold",
    label: "Deviation from max sold in line",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.dev_from_median_actives",
    label: "Deviation from median of actives in line",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "listing.dev_from_median_pendings",
    label: "Deviation from median of pendings in line",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "unit.dev_from_last_rent",
    label: "Deviation from last rent in line",
    isPercent: true,
    type: "Float",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "unit.dev_from_max_rent",
    label: "Deviation from max rent in line",
    isPercent: true,
    type: "Float",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "listing.dev_from_median_last_24m",
    label: "Deviation from sale last 2 years",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "unit.dev_from_median_last_24m_rent",
    label: "Deviation from rent last 2 years",
    isPercent: true,
    type: "Float",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "listing.nextOpenHouseDate",
    label: "Next Open House Date",
    type: "DateTime",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "unit.outdoor",
    label: "Unit is Outdoor",
    type: "Boolean",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "building.parkView",
    label: "Park View",
    type: "Boolean",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "building.pets",
    label: "Pets",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.pool",
    label: "Pool",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.premium",
    label: "Premium",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.prewar",
    label: "Pre-War",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "listing.priceChange",
    label: "Price Change",
    type: "Float",
    group: "Listing",
    isFilterable: true,
    prefix: "$"
  },
  {
    field: "listing.priceDiscountPct",
    label: "Price Discount Pct",
    isPercent: true,
    type: "Float",
    group: "Listing",
    isFilterable: true
  },
  {
    field: "unit.riverView",
    label: "River View",
    type: "Boolean",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "building.roofGarden",
    label: "Roof Garden",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "unit.rooms",
    label: "Rooms",
    type: "Float",
    group: "Unit",
    isFilterable: true
  },
  {
    field: "building.storageArea",
    label: "Storage Area",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "street.street",
    label: "Street",
    type: "String",
    isTextField: true,
    group: "Street",
    isGeo: true,
    isFilterable: true
  },
  {
    field: "building.units",
    label: "Units",
    type: "Int",
    isTextField: true,
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.utility",
    label: "Utility",
    type: "String",
    isTextField: true,
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.washerDryer",
    label: "Washer Dryer",
    type: "Boolean",
    group: "Building",
    isFilterable: true
  },
  {
    field: "building.yearBuilt",
    label: "Year Built",
    type: "Int",
    group: "Building",
    isFilterable: true
  },
  {
    field: "zipCode",
    label: "Zip Code",
    type: "Options",
    isTextField: true,
    group: "Building",
    isFilterable: true
  },
  {
    field: "tags.timestamp",
    label: "Tag Date",
    type: "DateTime",
    group: "Listing",
    isNested: true,
    nestedSortOptions: {
      mode :  "max",
      nested_path : "tags"
    },
    isFilterable: false
  }
];

export default fields;

export const fieldMap = new Map(
  fields.map(fieldConfig => [fieldConfig.field, fieldConfig])
);

export const orderMap = new Map(
  fields.map((fieldConfig, index) => [fieldConfig.field, index])
);
