import {
  convertQuery,
  buildAggregationPart
} from "../../utils/logical-elastic-query-adapter";
import { fieldMap } from "field-mappings/listings";
import metrics from '../../field-mappings/marketAnalysis';
import { aggregationGroupByOptions } from './listings-reducer';

import _ from 'lodash';

export const buildPropertiesQueryVariables = state => {
  const result = {
    query: buildQuery(state),
    sort: state.properties.sort,
    pageIndex: state.properties.pageIndex,
    size: state.properties.size,
    aggs: buildPropertyAggs(state)
  };

  return result;
};

export const buildNeighborhoodsQueryVariables = state => {
  const result = {
    query: buildQuery(state, "neighborhoods"),
    aggs: buildNeighborhoodAggs(state),
    size: 0
  };

  return result;
};

export const buildTrendsQueryVariables = state => {
  const query = buildQuery(state, "trends");
  if (state.selectedAreaName && state.aggregationGroupBy) {
    query.bool.filter.push({
      term: {
        [state.aggregationGroupBy]: state.selectedAreaName
      }
    });
  }
  const result = {
    query: query,
    aggs: buildTrendsAggs(state)
  };

  return result;
};

const buildPropertyAggs = () => {
  return {
    medianPrice: {
      percentiles: {
        field: "listing.price",
        percents: [50]
      }
    },
    medianPricePerSize: {
      percentiles: {
        field: "listing.pricePerSize",
        percents: [50]
      }
    },
    medianSize: {
      percentiles: {
        field: "unit.size",
        percents: [50]
      }
    }
  }
}


const buildNeighborhoodAggs = ({
  aggregationGroupBy,
  neighborhoods: { aggregationType, resolution }
}) => {
  const innerAggregation = {};
  metrics.forEach(item => {
    const metricAggregationType = item.aggregationType || aggregationType;
    const aggPart = buildAggregationPart(metricAggregationType, item.field);
    Object.assign(innerAggregation, aggPart)
  });

  const resolutions = {
    w: "'week #'w, yyyy",
    M: "MMM, yyyy",
    q: "MMM yyyy",
    y: "yyyy",
  };
  
  const format = resolutions[resolution];

  return {
    byHood: {
      terms: {
        field: aggregationGroupBy,
        size: 1000,
      },
      aggs: {
        byDate: {
          date_histogram: {
            field: "listing.listingDate",
            interval: `1${resolution}`,  
            format
          },
          aggs: {
            ...innerAggregation,
          },
        },
      },
    },
  };
};

const buildTrendsAggs = ({ trends: { selectedField, aggregationType, resolution } }) => {
  let aggPart;
  switch (aggregationType) {
    case 'median':
      aggPart = {
        [selectedField]: {
          percentiles: {
            field: selectedField,
            percents: [50],
            keyed: false
          }
        },
        [`${selectedField}_doc_count`]: {
          value_count: {
            field: selectedField
          }
        }
      }
      break;
    case 'avg': 
    case 'sum': 
    case 'value_count': 
      aggPart =  {
        [selectedField]: {
          [aggregationType]: {
            field: selectedField
          }
        },
        [`${selectedField}_doc_count`]: {
          value_count: {
            field: selectedField
          }
        }
      }
      break;
    default:
      break;
  }

  const aggs = {
    by_date: {
      date_histogram: {
        field: "listing.listingDate",
        interval: `1${resolution}`,
        time_zone: "Asia/Jerusalem",
        min_doc_count: 1
      },
      aggs: {...aggPart}
    }
  };

  return aggs;
};

const buildQuery = (state, source) => {
  const must = [];
  const filter = [];
  const should = [];

  filter.push(...baseFilter(state, source).filter);

  const searchQueryFilter = buildSearchQuery(state);
  must.push(...searchQueryFilter.must);
  should.push(...searchQueryFilter.should);
  filter.push(...convertQueryFilters(state).filter);
  filter.push(...buildUserTagFilter(state).filter);
  filter.push(...buildChangesFilter(state).filter);

  const query = {
    bool: {
      must,
      should,
      filter
    }
  };
  return query;
};

const baseFilter = (
  state, 
  source = 'listings'
) => {
  let resolution, filter = [];

  const getYearlyDateMath = () => {
    let sinceDate;
    if (source === "trends") sinceDate = "now-20y/y";
    else if (
      source === "neighborhoods" &&
      state.aggregationGroupBy === aggregationGroupByOptions.address
    )
      sinceDate = "now-3y/y";
    else sinceDate = "now-9y/y";
    return sinceDate;
  }

  const dateMaths = {
    w: "now-10w/w",
    M: "now-10M/M",
    q: "now-30M/M", 
    y: getYearlyDateMath()
  }

  switch (source) {
    case "neighborhoods":
      resolution = _.get(state, `${source}.resolution`);
      filter = [
        {
          range: {
            "listing.listingDate": {
              gte: dateMaths[resolution],
            },
          },
        },
      ];

      if (state.aggregationGroupBy === aggregationGroupByOptions.address)
        filter.push({
          term: {
            "neighborhood.neighborhoodName.keyword":
              state.neighborhoods.byAddressParentAreaName,
          },
        });

      break;
    case "trends":
      resolution = _.get(state, "trends.resolution");
      filter.push({
        range: {
          "listing.listingDate": {
            gte: dateMaths[resolution],
          },
        },
      });
      break;
    case "listings":
      filter.push({
        term: {
          "listing.isLatest": true,
        },
      });
      break;
    default:
      break;
  } 
  
  return { filter };
};

const STATUS_FIELD = "listing.status";
const LISTING_DATE_FIELD = "listing.listingDate";
const ACTIVE_STATUS_VALUE = "active";

const activeStatusFilter = convertQuery(fieldMap, {
  field: STATUS_FIELD,
  operator: "in",
  value: [ACTIVE_STATUS_VALUE]
});

const convertQueryFilters = ({ filters }) => {
  let queryFilters = [];
  let geoFilters = [];

  for (const filter of filters) {
    const fieldConfig = fieldMap.get(filter.field);
    if (!fieldConfig) {
      console.warn(`Bad filter detected`, filter);
    } else {
      let convertedQuery = convertQuery(fieldMap, filter);
      if (fieldConfig.isGeo) {
        geoFilters.push(convertedQuery);
      } else {
        if (fieldConfig.field === LISTING_DATE_FIELD) {
          // listing date filter should be optional (not filter) for listings of status "active"
          convertedQuery = {
            bool: { should: [activeStatusFilter, { ...convertedQuery }] }
          };
        }
        queryFilters.push(convertedQuery);
      }
    }
  }
  if (geoFilters.length) {
    queryFilters.push({ bool: { should: geoFilters } });
  }

  return { filter: queryFilters };
};

const buildChangesFilter = ({ isChangesView, listingEntityIds }) => {
  let filter = [];
  if (isChangesView) {
    filter = [
      {
        terms: {
          "entityId.keyword": listingEntityIds
        }
      }
    ];
  }
  return { filter };
};

const buildUserTagFilter = ({
  userTagId,
  tags: { isTaggedView, selectedTags }
}) => {
  let filter = [];
  if (userTagId && isTaggedView) {
    filter = [
      {
        nested: {
          path: "tags",
          query: {
            bool: {
              must: [
                {
                  exists: {
                    field: "tags"
                  }
                }
              ]
            }
          }
        }
      }
    ];
  } else if (selectedTags.length) {
    filter = [
      {
        nested: {
          path: "tags",
          query: {
            bool: {
              filter: [
                {
                  terms: {
                    "tags.user_tag_id": selectedTags
                  }
                }
              ]
            }
          }
        }
      }
    ];
  }
  return { filter };
};

export const buildSearchQuery = ({ searchString }) => {
  let must = [];
  let should = [];

  if (searchString && searchString.length) {
    must = [
      {
        match: {
          _search: {
            query: searchString,
            fuzziness: "AUTO"
          }
        }
      }
    ];
    should = [
      {
        match: {
          _search: {
            query: searchString
          }
        }
      },
      {
        match: {
          "address.address": {
            query: searchString
          }
        }
      },
      {
        match: {
          "displayData.beds": {
            query: searchString
          }
        }
      },
      {
        match: {
          "displayData.baths": {
            query: searchString
          }
        }
      }
    ];
  }

  return { must, should };
};
