import {
  buildPropertiesQueryVariables,
  buildNeighborhoodsQueryVariables,
  buildTrendsQueryVariables
} from "./query-builder";
import dequal from "dequal";
import { fieldMap as listingsFieldMap } from "field-mappings/listings";
import metrics from '../../field-mappings/marketAnalysis';
import extractAndRankHierarchicalAggs from './market-analysis-data-extractor';


import uuid from "uuid/v4";

const propertiesDefaultColumnFields = [
  "address.address",
  "unit.unit",
  "building.ownership",
  "listing.status",
  "unit.beds",
  "unit.baths",
  "unit.size",
  "listing.estimated_price",
  "unit.appreciation",
  "listing.pricePerSize",
  "listing.price",
  "listing.listingDate",
  "score.score",
  "deals.arbitrage.arbitrage",
  "deals.income.cap_rate",
  "deals.flip.target_multiple",
  "listing.priceChangePct",
  "unit.deviation_from_last_sold_in_line",
  "listing.last_reduction_date",
  "listing.daysOnMarket",
  "listing.number_of_reductions",
  "unit.price_purchase_deviation",
  "building.BldgFront",
  "building.BldgDepth",
  "listing.monthlies_psf",
  "listing.tax",
  "listing.common_charges",
  "listing.monthly",
  "building.unitsTotal",
  "neighborhood.neighborhoodName",
  "borough.boroughName",
  "changes.date",
  "changes.activity.field",
  "changes.activity.newValue",
  "changes.activity.previousValue",
  "deals.investments_keywords",
  "deals.flip.eirr",
  "unit.estimated_rent",
  "unit.taggedByExpLiMO",
  "unit.taggedByFSBO",
  "deals.flip.estimated_target_rent_income"
];

const baseFields = [
  "entityId",
  "unit.entityId",
  "unit.unit",
  "unit.size",
  "unit.beds",
  "unit.baths",
  "unit.isHouse",
  "unit.price_purchase_deviation",
  "unit.appreciation",
  "unit.smartzipInfo.SZemail",
  "unit.smartzipInfo.SZphone",
  "unit.smartzipInfo.email1",
  "unit.smartzipInfo.estPrice",
  "unit.smartzipInfo.firstName",
  "unit.smartzipInfo.lastName",
  "unit.smartzipInfo.phone1",
  "unit.smartzipInfo.rankCategory",
  "unit.hasOwnerInfo",
  "unit.hasEmail",
  "unit.hasPhone",
  "unit.ownerInfo.firstName",
  "unit.ownerInfo.lastName",
  "unit.ownerInfo.phone",
  "unit.ownerInfo.phone2",
  "unit.ownerInfo.phone3",
  "unit.ownerInfo.phone4",
  "unit.ownerInfo.phone5",
  "unit.ownerInfo.email",
  "unit.ownerInfo.email2",
  "unit.segments.building",
  "unit.segments.neighborhood",
  "unit.segments.motherhood",
  "building.entityId",
  "building.ownership",
  "building.houseType",
  "building.grosssf",
  "borough.boroughName",
  "neighborhood.neighborhoodName",
  "listing.entityId",
  "listing.estimated_price",
  "listing.estimated_price_field",
  "listing.estimated_price_fallback",
  "listing.bld_price_deviation",
  "unit.estimated_rent",
  "unit.estimated_rent_field",
  "listing.estimated_taxes",
  "listing.estimated_taxes_field",
  "listing.estimated_maintenance",
  "listing.estimated_maintenance_field",
  "listing.estimated_monthlies",
  "listing.estimated_monthlies_psf",
  "listing.number_of_reductions",
  "listing.status",
  "listing.price",
  "listing.pricePerSize",
  "listing.listingDate",
  "listing.daysOnMarket",
  "listing.priceChangePct",
  "listing.images.photos",
  "listing.images.floorplans",
  "listing.openHouseDates.date",
  "listing.openHouseDates.timeRange",
  "address.address",
  "address.geolocation.lat",
  "address.geolocation.lon",
  "tags.id",
  "tags.user_id",
  "tags.user_tag_id",
  "tags.timestamp",
  "tags.display_tag",
  "tags.tagged_by",
  "deals.flip.target_multiple",
  "deals.flip.upper_beds",
  "deals.flip.upper_baths",
  "deals.flip.target_sale_price",
  "deals.income.cap_rate",
  "deals.income.out_of_pocket",
  "deals.income.out_of_pocket_pct",
  "deals.income.cash_flow",
  "deals.income.calc_mode_yearly_expenses",
  "deals.arbitrage.arbitrage",
  "score.score",
  "changes.date",
  "changes.activity.field",
  "changes.activity.newValue",
  "changes.activity.previousValue",
  "unit.last_sale_in_line_date",
  "unit.last_pending_in_line_date",
];

const defaultFilter = [
  // Example:
  // {
  //   field: "listing.status",
  //   operator: "in",
  //   value: ["active"]
  // },
  // {
  //   field: "listing.listingDate",
  //   operator: "range",
  //   value: {
  //     from: moment()
  //       .subtract(1, "year")
  //       .format("YYYY-MM-DD")
  //   }
  // }
];

const scoreSort = {
  field: "_score",
  order: "desc"
};

const buildSortObj = state => {
  const sortObj = [scoreSort];
  const fieldConfig = listingsFieldMap.get(state.properties.sortValue);
  if (!fieldConfig) {
    console.warn(`Field config could not be found !`);
  } else {
    const newSortField = fieldConfig.isTextField
      ? `${fieldConfig.field}.keyword`
      : fieldConfig.field;
    const newSort = {
      field: newSortField,
      order: state.properties.sortOrder,
      isNested: !!fieldConfig.isNested,
      nestedSortOptions: fieldConfig.nestedSortOptions || {},
    };
    sortObj.push(newSort);
  }
  return sortObj;
};

const propertiesDataUpdated = ({ prevState, newState }) => {
  if (newState.requestId !== prevState.properties.lastRequestId) {
    console.log(`Blocked request result, 
    lastRequestId: ${prevState.properties.lastRequestId}, current: ${newState.requestId}`);
    return prevState;
  }

  const { items, pages, total, size, aggs, pageIndex, error } = newState;
  const { properties: prevPropertiesState } = prevState;

  let newPropertiesState = {
    appendData: false,
    loading: false,
    error,
    pages,
    pageIndex,
    total,
    size,
    aggs
  };

  const appendData = pageIndex > 0;
  newPropertiesState.data = appendData
    ? [...prevPropertiesState.data, ...items]
    : items;

  return {
    ...prevState,
    properties: {
      ...prevPropertiesState,
      ...newPropertiesState
    }
  };
};

const neighborhoodDataUpdated = ({ prevState, newState }) => {
  const { loading, error, aggs } = newState;
  const { neighborhoods: prevNhdState } = prevState;

  let newSelectedNeighborhood = prevState.trends.neighborhoodName;
  let rankedAggs, mergedColumnsSet;
  if (!error && aggs) {
    const result = extractAndRankHierarchicalAggs({
      aggregations: aggs.byHood,
      aggregationType: prevState.neighborhoods.aggregationType
    });
    rankedAggs = result.rankedAggs;
    mergedColumnsSet = result.mergedColumnsSet;
    
    if (rankedAggs.length) {
      newSelectedNeighborhood = rankedAggs[0].name;
    }
  }

  let result = {
    ...prevState,
    neighborhoods: {
      ...prevNhdState,
      loading,
      error,
      data: rankedAggs,   //Todo: Change data structure ? Hood / Address / etc' ?
      columns: [...mergedColumnsSet]
    },
    trends: {
      ...prevState.trends,
      neighborhoodName: newSelectedNeighborhood
    }
  };

  return result;
};

const propertiesScrolled = ({ prevState, newState }) => {
  const result = {
    ...prevState,
    properties: {
      ...prevState.properties,
      loading: true,
      appendData: true,
      pageIndex: prevState.properties.pageIndex + 1
    }
  };

  result.properties.queryVariables = buildPropertiesQueryVariables(result);
  result.properties.lastRequestId = uuid();
  return result;
};

const propertiesSortValueUpdated = ({ prevState, newState }) => {
  if (newState === prevState.sortValue) return prevState;
  const result = {
    ...prevState,
    properties: {
      ...prevState.properties,
      sortValue: newState,
      pageIndex: 0,
      appendData: false
    }
  };

  result.properties.sort = buildSortObj(result);
  result.properties.queryVariables = buildPropertiesQueryVariables(result);
  result.properties.lastRequestId = uuid();
  return result;
};

const onApartmentHistory = ({ prevState, newState }) => {
  if (newState === prevState.sortValue) return prevState;
  const result = {
    ...prevState,
    saleListings: newState.saleListings
  };
  
  return result;
};

const propertiesSortOrderUpdated = ({ prevState, newState }) => {
  if (newState === prevState.sortOrder) return prevState;
  const result = {
    ...prevState,
    properties: {
      ...prevState.properties,
      sortOrder: newState,
      pageIndex: 0,
      appendData: false
    }
  };

  result.properties.sort = buildSortObj(result);
  result.properties.queryVariables = buildPropertiesQueryVariables(result);
  result.properties.lastRequestId = uuid();
  return result;
};

const filtersUpdated = ({ prevState, newState }) => {
  if (dequal(prevState.filters, newState)) return prevState;

  const result = {
    ...prevState,
    filters: newState,
    properties: {
      ...prevState.properties,
      pageIndex: 0,
      appendData: false
    }
  };

  result.properties.queryVariables = buildPropertiesQueryVariables(result);
  result.properties.lastRequestId = uuid();
  result.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(
    result
  );
  result.trends.queryVariables = buildTrendsQueryVariables(result);
  return result;
};

const resetFilters = ({ prevState }) => {
  const result = {
    ...prevState,
    filters: defaultFilter,
    properties: {
      ...prevState.properties,
      pageIndex: 0,
      appendData: false
    }
  };

  result.properties.queryVariables = buildPropertiesQueryVariables(result);
  result.properties.lastRequestId = uuid();
  result.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(
    result
  );
  result.trends.queryVariables = buildTrendsQueryVariables(result);
  return result;
};

const searchUpdated = ({ prevState, newState }) => {
  let result = {
    ...prevState,
    searchString: newState,
    properties: {
      ...prevState.properties,
      pageIndex: 0,
      appendData: false
    }
  };
  result.properties.queryVariables = buildPropertiesQueryVariables(result);
  result.properties.lastRequestId = uuid();
  result.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(
    result
  );
  result.trends.queryVariables = buildTrendsQueryVariables(result);
  return result;
};

const userTagsUpdated = ({ prevState, newState }) => {
  let result = {
    ...prevState,
    userTagId: newState,
    properties: {
      ...prevState.properties,
      pageIndex: 0,
      appendData: false
    }
  };

  // We don't want to trigget an all-around update,
  // Unless someone is actually waiting for the user-tags to load
  if (prevState.tags.isTaggedView) {
    result.properties.queryVariables = buildPropertiesQueryVariables(result);
    result.properties.lastRequestId = uuid();
    result.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(
      result
    );
    result.trends.queryVariables = buildTrendsQueryVariables(result);
  }

  return result;
};

const changesViewUpdated = ({
  prevState,
  newState: { changesState, listingEntityIds }
}) => {
  if (
    prevState.isChangesView === changesState &&
    prevState.listingEntityIds === listingEntityIds
  )
    return prevState;

  let result = {
    ...prevState,
    isChangesView: changesState !== null,
    changesViewState: changesState,
    listingEntityIds,
    properties: {
      ...prevState.properties,
      pageIndex: 0,
      appendData: false
    }
  };
  result.properties.queryVariables = buildPropertiesQueryVariables(result);
  result.properties.lastRequestId = uuid();
  result.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(
    result
  );
  result.trends.queryVariables = buildTrendsQueryVariables(result);
  return result;
};

const tagViewTagToggled = ({ prevState, newState }) => {
  const currentTags = prevState.tags.selectedTags;
  const currentTagsSet = new Set(currentTags);
  if (currentTagsSet.has(newState)) {
    currentTagsSet.delete(newState);
  } else {
    currentTagsSet.add(newState);
  }

  let result = {
    ...prevState,
    tags: {
      ...prevState.tags,
      isTaggedView: false,
      selectedTags: [...currentTagsSet]
    },
    properties: {
      ...prevState.properties,
      pageIndex: 0,
      appendData: false
    }
  };

  result.properties.queryVariables = buildPropertiesQueryVariables(result);
  result.properties.lastRequestId = uuid();
  result.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(
    result
  );
  result.trends.queryVariables = buildTrendsQueryVariables(result);
  return result;
};

const tagViewUpdated = ({ prevState, newState }) => {
  let result = {
    ...prevState,
    tags: {
      ...prevState.tags,
      isTaggedView: newState,
      selectedTags: []
    },
    properties: {
      ...prevState.properties,
      pageIndex: 0,
      appendData: false
    }
  };
  result.properties.queryVariables = buildPropertiesQueryVariables(result);
  result.properties.lastRequestId = uuid();
  result.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(
    result
  );
  result.trends.queryVariables = buildTrendsQueryVariables(result);
  return result;
};

const propertiesColumnsUpdated = ({ prevState, newState }) => {
  if (!Array.isArray(newState) || !newState.length) return prevState;
  let newColumns = new Set([...prevState.properties.fields]);
  newState.forEach(change => {
    if (!change.isVisible) {
      newColumns.delete(change.field);
    } else {
      newColumns.add(change.field);
    }
  });

  let baseAndNewFields = [...new Set([...baseFields, ...newColumns])];

  let result = {
    ...prevState,
    properties: {
      ...prevState.properties,
      columns: newColumns,
      fields: baseAndNewFields
    }
  };

  // We SHOULD refetch the data but the query itself hasn't changed
  // just the fields that we are fetching,
  // So no refresh on this one
  return result;
};

const selectedViewUpdated = ({ prevState, newState }) => {
  let result = {
    ...prevState,
    isNeighborhoodView: newState
  };

  return result;
};

const neighborhoodsFieldsUpdated = ({ prevState, newState }) => {
  if (!Array.isArray(newState) || !newState.length) return prevState;
  let newFields = new Set([...prevState.neighborhoods.fields]);

  newState.forEach(change => {
    if (!change.isVisible) {
      newFields.delete(change.field);
    } else {
      newFields.add(change.field);
    }
  });

  let result = {
    ...prevState,
    neighborhoods: {
      ...prevState.neighborhoods,
      fields: [...newFields]
    }
  };

  // Aggregate fields ARE part of the query,
  // so we should rebuild it here
  result.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(
    result
  );
  return result;
};

const propertiesLoading = ({ prevState }) => {
  return {
    ...prevState,
    properties: {
      ...prevState.properties,
      loading: true
    }
  };
};

const neighborhoodsLoading = ({ prevState }) => {
  return {
    ...prevState,
    neighborhoods: {
      ...prevState.neighborhoods,
      loading: true
    }
  };
};

const trendsLoading = ({ prevState }) => {
  return {
    ...prevState,
    trends: {
      ...prevState.trends,
      loading: true
    }
  };
};

const selectedNeighborhoodUpdated = ({ prevState, newState }) => {
  const result = {
    ...prevState,
    selectedAreaName: newState
  };
  result.trends.queryVariables = buildTrendsQueryVariables(result);
  return result;
};

const trendsFieldUpdated = ({ prevState, newState }) => {
  const result = {
    ...prevState,
    selectedAreaName: newState.neighborhoodName,
    trends: {
      ...prevState.trends,
      selectedField: newState.selectedField,
      aggregationType: newState.aggregationType
    }
  };
  result.trends.queryVariables = buildTrendsQueryVariables(result);
  return result;
};

const trendsResolutionUpdated = ({ prevState, newState }) => {
  const result = {
    ...prevState,
    trends: {
      ...prevState.trends,
      resolution: newState,
      loading: true
    }
  };
  result.trends.queryVariables = buildTrendsQueryVariables(result);
  return result;
};

const neighborhoodsResolutionUpdated = ({ prevState, newState }) => {
  const result = {
    ...prevState,
    neighborhoods: {
      ...prevState.neighborhoods,
      resolution: newState,
      loading: true
    }
  };
  result.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(result);  
  return result;
};

const neighborhoodsAggregationGroupByUpdated = ({ prevState, newState }) => {
  const isSwitchFromAddress =
    prevState.aggregationGroupBy !== newState.aggregationGroupBy &&
    prevState.aggregationGroupBy === aggregationGroupByOptions.address;
  const result = {
    ...prevState,
    aggregationGroupBy: newState,
    selectedAreaName:
      isSwitchFromAddress
        ? prevState.neighborhoods.byAddressParentAreaName // Go back to parent hood when moving from an address to hood
        : null,
    neighborhoods: {
      ...prevState.neighborhoods,
      loading: true,
    },
  };
  result.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(
    result
  );
  return result;
};

const neighborhoodsGroupByAddressRequested = ({ prevState, newState }) => {
  const result = {
    ...prevState,
    selectedAreaName: newState.neighborhoodName,
    aggregationGroupBy: newState.groupBy,
    neighborhoods: {
      ...prevState.neighborhoods,
      byAddressParentAreaName: newState.neighborhoodName,
      loading: true
    }
  };
  result.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(result);  
  return result;
};

const neighborhoodsAggregationTypeUpdated = ({ prevState, newState }) => {
  const result = {
    ...prevState,
    neighborhoods: {
      ...prevState.neighborhoods,
      aggregationType: newState,
      loading: true
    }
  };
  result.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(result);  
  return result;
};

const trendsDataUpdated = ({ prevState, newState }) => {
  const { data, error } = newState;
  return {
    ...prevState,
    trends: {
      ...prevState.trends,
      data,
      loading: false,
      error
    }
  };
};

const reducer = (prevState, action) => {
  const { type, payload: newState } = action;
  let result = prevState;

  switch (type) {
    case actionTypes.SELECTED_VIEW_UPDATED:
      result = selectedViewUpdated({ prevState, newState });
      break;
      
    case actionTypes.TAGGED_VIEW_TAG_TOGGLED:
      result = tagViewTagToggled({ prevState, newState });
      break;

    case actionTypes.TAGGED_VIEW_UPDATED:
      result = tagViewUpdated({ prevState, newState });
      break;

    case actionTypes.CHANGES_VIEW_UPDATED:
      result = changesViewUpdated({ prevState, newState });
      break;

    case actionTypes.SEARCH_UPDATED:
      result = searchUpdated({ prevState, newState });
      break;

    case actionTypes.FILTERS_UPDATED:
      result = filtersUpdated({ prevState, newState });
      break;

    case actionTypes.RESET_FILTERS:
      result = resetFilters({ prevState, newState });
      break;

    case actionTypes.USER_TAGS_UPDATED:
      result = userTagsUpdated({ prevState, newState });
      break;

    case actionTypes.PROPERTIES_LOADING:
      result = propertiesLoading({ prevState, newState });
      break;

    case actionTypes.PROPERTIES_SCROLLED:
      result = propertiesScrolled({ prevState, newState });
      break;

    case actionTypes.PROPERTIES_DATA_UPDATED:
      result = propertiesDataUpdated({ prevState, newState });
      break;

    case actionTypes.PROPERTIES_SORT_ORDER_UPDATED:
      result = propertiesSortOrderUpdated({ prevState, newState });
      break;

    case actionTypes.PROPERTIES_SORT_VALUE_UPDATED:
      result = propertiesSortValueUpdated({ prevState, newState });
      break;
      
    case actionTypes.PROPERTIES_COLUMNS_UPDATED:
      result = propertiesColumnsUpdated({ prevState, newState });
      break;

    case actionTypes.NEIGHBORHOODS_LOADING:
      result = neighborhoodsLoading({ prevState, newState });
      break;

    case actionTypes.NEIGHBORHOODS_FIELDS_UPDATED:
      result = neighborhoodsFieldsUpdated({ prevState, newState });
      break;

    case actionTypes.NEIGHBORHOODS_DATA_UPDATED:
      result = neighborhoodDataUpdated({ prevState, newState });
      break;

    case actionTypes.SELECTED_NEIGHBORHOOD_UPDATED:
      result = selectedNeighborhoodUpdated({ prevState, newState });
      break;

    case actionTypes.NEIGHBORHOODS_AGGREGATION_GROUP_BY_UPDATED:
      result = neighborhoodsAggregationGroupByUpdated({ prevState, newState });
      break;

    case actionTypes.NEIGHBORHOODS_AGGREGATION_TYPE_UPDATED:
      result = neighborhoodsAggregationTypeUpdated({ prevState, newState });
      break;

    case actionTypes.NEIGHBORHOODS_RESOLUTION_UPDATED:
      result = neighborhoodsResolutionUpdated({ prevState, newState });
      break;

    case actionTypes.NEIGHBORHOODS_GROUP_BY_ADDRESS_REQUESTED:
      result = neighborhoodsGroupByAddressRequested({ prevState, newState });
      break;

    case actionTypes.TRENDS_LOADING:
      result = trendsLoading({ prevState, newState });
      break;

    case actionTypes.TRENDS_CHART_CONTROL_UPDATED:
      result = trendsFieldUpdated({ prevState, newState });
      break;

    case actionTypes.TRENDS_DATA_UPDATED:
      result = trendsDataUpdated({ prevState, newState });
      break;

    case actionTypes.TRENDS_RESOLUTION_UPDATED:
      result = trendsResolutionUpdated({ prevState, newState });
      break;

    case actionTypes.ON_APARTMENT_HISTORY:
      result = onApartmentHistory({ prevState, newState });
      break;

    default:
      throw new Error(`Unknown action type "${type}"`);
  }
  return result;
};

export const actionTypes = {
  USER_TAGS_UPDATED: "USER_TAGS_UPDATED",
  SEARCH_UPDATED: "SEARCH_UPDATED",
  FILTERS_UPDATED: "FILTERS_UPDATED",
  RESET_FILTERS: "RESET_FILTERS",
  TAGGED_VIEW_UPDATED: "TAGGED_VIEW_UPDATED",
  TAGGED_VIEW_TAG_TOGGLED: "TAGGED_VIEW_TAG_TOGGLED",
  CHANGES_VIEW_UPDATED: "CHANGES_VIEW_UPDATED",
  SELECTED_VIEW_UPDATED: "SELECTED_VIEW_UPDATED",
  PROPERTIES_LOADING: "PROPERTIES_LOADING",
  PROPERTIES_SORT_VALUE_UPDATED: "PROPERTIES_SORT_VALUE_UPDATED",
  PROPERTIES_SORT_ORDER_UPDATED: "PROPERTIES_SORT_ORDER_UPDATED",
  PROPERTIES_SCROLLED: "PROPERTIES_SCROLLED",
  PROPERTIES_DATA_UPDATED: "PROPERTIES_DATA_UPDATED",
  PROPERTIES_COLUMNS_UPDATED: "PROPERTIES_COLUMNS_UPDATED",
  NEIGHBORHOODS_LOADING: "NEIGHBORHOOODS_LOADING",
  NEIGHBORHOODS_DATA_UPDATED: "NEIGHBORHOODS_DATA_UPDATED",
  SELECTED_NEIGHBORHOOD_UPDATED: "SELECTED_NEIGHBORHOOD_UPDATED",
  NEIGHBORHOODS_FIELDS_UPDATED: "NEIGHBORHOODS_FIELDS_UPDATED",
  NEIGHBORHOODS_RESOLUTION_UPDATED: "NEIGHBORHOODS_RESOLUTION_UPDATED",
  NEIGHBORHOODS_AGGREGATION_GROUP_BY_UPDATED: "NEIGHBORHOODS_AGGREGATION_GROUP_BY_UPDATED",
  NEIGHBORHOODS_AGGREGATION_TYPE_UPDATED: "NEIGHBORHOODS_AGGREGATION_TYPE_UPDATED",
  NEIGHBORHOODS_GROUP_BY_ADDRESS_REQUESTED: "NEIGHBORHOODS_GROUP_BY_ADDRESS_REQUESTED",
  TRENDS_LOADING: "TRENDS_LOADING",
  TRENDS_CHART_CONTROL_UPDATED: "TRENDS_CHART_CONTROL_UPDATED",
  TRENDS_DATA_UPDATED: "TRENDS_DATA_UPDATED",
  TRENDS_RESOLUTION_UPDATED: "TRENDS_RESOLUTION_UPDATED",
};

const defaultSortOrder = "desc";
const defaultSortValue = "listing.listingDate";

export const initialState = () => {
  const sortValue = defaultSortValue;
  const sortOrder = defaultSortOrder;
  const state = {
    tags: {
      isTaggedView: false,
      selectedTags: []
    },
    isChangesView: false,
    changesViewState: "",
    isNeighborhoodView: false,
    userTagId: null,
    searchString: window.sessionStorage.getItem("searchString") || "",
    filters: defaultFilter,
    selectedAreaName: null,
    aggregationGroupBy: aggregationGroupByOptions.neighborhood,
    properties: {
      error: false,
      loading: true,
      data: [],
      lastRequestId: null,
      fields: [...new Set([...baseFields, ...propertiesDefaultColumnFields])],
      columns: propertiesDefaultColumnFields,
      appendData: false,
      total: null,
      sortValue,
      sortOrder,
      pageIndex: 0,
      size: 30,
      aggs: {}
    },
    neighborhoods: {
      loading: false,
      error: null,
      data: [],
      aggregationType: aggregationTypes.average,
      metrics: metrics,
      resolution: "y",
      columns: [],
      byAddressParentAreaName: null
    },
    trends: {
      loading: false,
      error: null,
      data: [],
      resolution: "y",
      selectedField: "listing.pricePerSize",
      aggregationType: aggregationTypes.average
    }
  };
  state.properties.sort = buildSortObj(state);
  state.neighborhoods.queryVariables = buildNeighborhoodsQueryVariables(state);
  state.trends.queryVariables = buildTrendsQueryVariables(state);
  state.properties.queryVariables = buildPropertiesQueryVariables(state);
  state.properties.lastRequestId = uuid();
  return state;
};

export const aggregationTypes = {
  median: "median",
  average: "avg",
};

export const aggregationGroupByOptions = {
  borough: "borough.boroughName.keyword",
  neighborhoodGroup: "neighborhood.neighborhoodGroup.keyword",
  neighborhood: "neighborhood.neighborhoodName.keyword",
  address: "address.address.keyword",
}

export const sortOptions = [
  { value: "score.score", displayName: "Score" },
  { value: "listing.listingDate", displayName: "Listing Date" },
  { value: "unit.last_sale_in_line_date", displayName: "Last Sale In Line Date" },
  { value: "unit.last_pending_in_line_date", displayName: "Last Pending In Line Date" },
  { value: "listing.listingDate", displayName: "Listing Date" },
  { value: "listing.estimated_price", displayName: "Est. Price" },
  { value: "listing.price", displayName: "Price" },
  { value: "listing.estimated_monthlies_psf", displayName: "Est. Monthlies psf" },
  { value: "listing.estimated_monthlies", displayName: "Est. Monthlies" },
  { value: "listing.monthly", displayName: "Monthlies" },
  { value: "listing.pricePerSize", displayName: "PSF" },
  { value: "unit.size", displayName: "Size" },
  { value: "listing.status", displayName: "Status" },
  { value: "listing.priceChangePct", displayName: "Price Reduction" },
  { value: "deals.flip.target_multiple", displayName: "Fix & Flip" },
  { value: "deals.income.cap_rate", displayName: "Cap Rate" },
  { value: "deals.income.out_of_pocket", displayName: "Down Payment" },
  { value: "deals.income.out_of_pocket_pct", displayName: "Down Payment %" },
  { value: "deals.income.cash_on_cash", displayName: "Cash On Cash" },
  { value: "deals.income.cash_flow", displayName: "Cash Flow" },
  { value: "deals.arbitrage.arbitrage", displayName: "Arbitrage" },
  { value: "unit.appreciation", displayName: "Appreciation" },
  { value: "listing.bld_price_deviation", displayName: "Dev. from building price"},
  { value: "listing.bld_psf_deviation", displayName: "Dev. from building Psf" },
  { value: "listing.bld_size_deviation", displayName: "Dev. from building size" },
  { value: "listing.bld_dom_deviation", displayName: "Dev. from building dom" },
  { value: "listing.bld_negotiability_deviation", displayName: "Dev. from building negotiability" },
  { value: "listing.bld_monthlies_deviation", displayName: "Dev. from building monthlies" },
  { value: "listing.nhd_price_deviation", displayName: "Dev. from neighbourhood price"},
  { value: "listing.nhd_psf_deviation", displayName: "Dev. from neighbourhood Psf" },
  { value: "listing.nhd_size_deviation", displayName: "Dev. from neighbourhood size" },
  { value: "listing.nhd_dom_deviation", displayName: "Dev. from neighbourhood dom" },
  { value: "listing.nhd_negotiability_deviation", displayName: "Dev. from neighbourhood negotiability" },
  { value: "listing.nhd_monthlies_deviation", displayName: "Dev. from neighbourhood monthlies" },
  { value: "listing.price_purchase_deviation", displayName: "Dev. from last subject sold"},
  { value: "listing.dev_from_last_sold", displayName: "Dev. from last sold in line"},
  { value: "listing.dev_from_max_sold", displayName: "Dev. from max sold in line"},
  { value: "listing.dev_from_median_last_24m", displayName: "Dev. from median last 24m"},
  { value: "listing.dev_from_median_actives", displayName: "Dev. from median of actives in line"},
  { value: "listing.dev_from_median_pendings", displayName: "Dev. from median of pendings in line"},
  { value: "unit.dev_from_last_rent", displayName: "Dev. from last rent in line"},
  { value: "unit.dev_from_max_rent", displayName: "Dev. from max rent in line"},
  { value: "listing.dev_from_median_last_24m_rent", displayName: "Dev. from median last 24m - rent"},
  { value: "listing.daysOnMarket", displayName: "DOM" },
  {
    value: "listing.number_of_reductions",
    displayName: "Number Of Reductions"
  },
  { value: "neighborhood.neighborhoodName", displayName: "Neighborhood" },
  { value: "address.address", displayName: "Address" },
  { value: "tags.timestamp", displayName: "Tag date" }


];

export default reducer;
