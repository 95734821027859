
import S from '../utils/strings';

const metrics = [
  {
    field: "listing.price",
    label: "Price",
    type: "Int",
    isTextField: false,
    formatter: S.price,
  },
  {
    field: "listing.pricePerSize",
    label: "Ppsf",
    type: "Float",
    isTextField: false,
    formatter: S.price,
  },
  {
    field: "score.score",
    label: "Score Sum",
    type: "Int",
    isTextField: false,
    formatter: S.decimal,
  },
  {
    field: "deals.arbitrage.arbitrage",
    label: "Arbitrage",
    type: "Float",
    isTextField: false,
    formatter: S.toPercentageAndFormat,
  },
  {
    field: "unit.appreciation",
    label: "Appreciation",
    type: "Float",
    isTextField: false,
    formatter: S.toPercentageAndFormat,
  },
  {
    field: "deals.income.cap_rate",
    label: "Cap Rate",
    type: "Float",
    isTextField: false,
    formatter: S.toPercentageAndFormat,
  },
  {
    field: "unit.size",
    label: "Size",
    type: "Int",
    isTextField: false,
    formatter: S.size,
  },
  {
    field: "listing.daysOnMarket",
    label: "DOM",
    type: "Int",
    isTextField: false,
    formatter: S.daysOnMarket,
  },
  {
    field: "listing.priceDiscountPct",
    label: "Discount",
    type: "Float",
    isTextField: false,
    formatter: S.toPercentageAndFormat,
  },
  {
    field: "listing.priceChange",
    label: "Price Change",
    type: "Float",
    isTextField: false,
    formatter: S.price,
  },
  {
    field: "unit.price_purchase_deviation",
    label: "Purchase Price Deviation",
    type: "Float",
    isTextField: false,
    formatter: S.toPercentageAndFormat,
  },
  {
    field: "listing.price",
    aggregationType: "value_count",
    label: "Inventory",
    type: "Int",
    isTextField: false,
  },
  {
    field: "listing.tax",
    label: "Tax",
    type: "Float",
    isTextField: false,
    formatter: S.price,
  },
  {
    field: "listing.common_charges",
    label: "Common Charges",
    type: "Float",
    isTextField: false,
    formatter: S.price,
  },
  {
    field: "unit.estimated_rent",
    label: "Estimated Rent",
    type: "Float",
    isTextField: false,
    formatter: S.price,
  },
  {
    field: "deals.income.annual_rent_psf",
    label: "Annual Rent Psf (approx)",
    type: "Float",
    isTextField: false,
    formatter: S.price,
  },
  {
    field: "listing.monthly",
    label: "Monthlies",
    type: "Int",
    isTextField: false,
    formatter: S.price,
  },
  {
    field: "listing.monthlies_psf",
    label: "Monthlies Psf",
    type: "Float",
    isTextField: false,
    formatter: S.priceDecimal,
  },
  {
    field: "listing.price",
    aggregationType: "sum",
    label: "Price",
    type: "Int",
    isTextField: false,
    formatter: S.price,
  },
  {
    field: "unit.size",
    aggregationType: "sum",
    label: "Size",
    type: "Int",
    isTextField: false,
    formatter: S.size,
  },
  {
    field: "deals.flip.convertible_bed_size",
    aggregationType: "value_count",
    label: "Convertible",
    type: "Int",
    isTextField: false,
  },
  {
    field: "listing.priceChange",
    aggregationType: "sum",
    label: "Price Change",
    type: "Float",
    isTextField: false,
    formatter: S.price,
  },
  {
    field: "deals.flip.nhd_upper_price_deviation",
    label: "Upper Price Deviation",
    type: "Float",
    isTextField: false,
    formatter: S.toPercentageAndFormat,
  },
];

export default metrics;

export const getLabeledMetricsMap = (aggregationType) =>
  new Map(
    metrics.map((metricConfig) => {
      const key = `${aggregationType}_${metricConfig.field}`;
      const aggType = metricConfig.aggregationType || aggregationType
      return [
        key, 
        {
          ...metricConfig,
          aggregationType : aggType,
          displayLabel : `${aggsDictionary[aggType]} ${metricConfig.label}`,
        }
      ];
    })
  );

export const aggsDictionary = {
  median: 'Median',
  avg: 'Average',
  sum: 'Accumulated',
  value_count: 'Number of'
};
