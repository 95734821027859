export const convertQuery = (fieldMap, query) => {
  let fieldName = query.field;
  const fieldConfig = fieldMap.get(fieldName);
  if (!fieldConfig) {
    throw new Error(`Field config '${fieldName}' not found`);
  }

  const filter = {};
  switch (query.operator) {
    case "=":
      filter.term = {
        [fieldName]: query.value
      };
      break;
    case "like":
      filter.match = {
        [fieldName]: query.value
      };

      break;
    case "!=":
      filter.must_not = {
        bool: {
          filter: {
            term: {
              [fieldName]: query.value
            }
          }
        }
      };
      break;
    case ">":
      filter.range = {
        [query.field]: {
          gt: query.value
        }
      };
      break;
    case ">=":
      filter.range = {
        [query.field]: {
          gte: query.value
        }
      };
      break;
    case "range":
      filter.range = {
        [query.field]: {}
      };
      const value = query.value;
      if (value.from !== null && value.from !== undefined) {
        filter.range[query.field].gte = query.value.from;
      }
      if (value.to !== null && value.to !== undefined) {
        filter.range[query.field].lte = query.value.to;
      }
      break;
    case "<":
      filter.range = {
        [query.field]: {
          lt: query.value
        }
      };
      break;
    case "in":
      const terms = {
        [fieldConfig.isTextField ? `${fieldName}.keyword` : fieldName]: query.value
      }

      if (!fieldConfig.nested) {
        filter.terms = terms;
      } else {
        filter.nested = {
          "path": fieldConfig.nested,
          "query": {
            "bool": {
              "filter": {
                "terms": terms
              }
            }
          }
        }
      }

      break;
    case "<=":
      filter.range = {
        [query.field]: {
          lte: query.value
        }
      };
      break;
    case "exists":
      filter.exists = {
          field: fieldName
      }
      break;
    case "not_exists":
      filter.bool = {
        must_not: {
          exists: {
            field: fieldName
          }
        }
      };
      break;
    default:
      console.warn(`Unknown operator ${query.operator}`);
      return null;
  }

  return filter;
};



export const buildAggregationPart = (aggregationType, fieldName) => {
  let result;
  const buckets_path = `${aggregationType}__${fieldName.replace(/[.]/g, '-dot-')}`
  switch (aggregationType) {
    case 'median':
      result = {
        [`${buckets_path}`]: {
          percentiles: {
            field: fieldName,
            percents: [50]
          }
        },
        [`${buckets_path}_lag`]: {
          serial_diff: {
            buckets_path: `${buckets_path}.50`,
            lag: 1,
          },
        },
        [`${buckets_path}_lag_pct`]: {
          bucket_script: {
            buckets_path: {
              [`${buckets_path}_lag`]: `${buckets_path}_lag`,
              [`${buckets_path}`]: `${buckets_path}.50`,
            },
            script:
              `params['${buckets_path}_lag'] / (params['${buckets_path}'] - params['${buckets_path}_lag'])`,
          },
        },
        [`${buckets_path}_doc_count`]: {
          value_count: {
            field: fieldName
          }
        }
      }
      break;
    case 'avg': 
    case 'sum': 
    case 'value_count': 
      result =  {
        [`${buckets_path}`]: {
          [aggregationType]: {
            field: fieldName
          }
        },
        [`${buckets_path}_lag`]: {
          serial_diff: {
            buckets_path: `${buckets_path}`,
            lag: 1,
          },
        },
        [`${buckets_path}_lag_pct`]: {
          bucket_script: {
            buckets_path: {
              [`${buckets_path}_lag`]: `${buckets_path}_lag`,
              [`${buckets_path}`]: `${buckets_path}`,
            },
            script:
            `params['${buckets_path}_lag'] / (params['${buckets_path}'] - params['${buckets_path}_lag'])`,
          },
        },
        [`${buckets_path}_doc_count`]: {
          value_count: {
            field: fieldName
          }
        }
      }
      break;
    
    default:
      break;
  }
  return result;
}