import React from "react";
import gql from "graphql-tag";

export const QUERY_SEARCH_GROUP_TAGS = gql`
  query searchGroups {
    searchGroups {
      items {
        id
        creator_sub
        creator_name
        name
        tags {
          id
          name
        }
        users {
          sub
          name
        }
      }
    }
  }
`;

const MUTATION_CREATE_TAG = gql`
  mutation createTag($input: CreateTagInput!) {
    createTag(input: $input) {
      id
    }
  }
`;
const createTagVariables = ({ userTagId, entityId }) => ({
  input: {
    user_tag_id: userTagId,
    entityid: entityId
  }
});

export const createTag = async ({ client, userTagId, entityId }) => {
  try {
    const {
      data: { createTag: data }
    } = await client.mutate({
      mutation: MUTATION_CREATE_TAG,
      variables: createTagVariables({ userTagId, entityId }),
      update: () => client.resetStore()
    });
    return { data };
  } catch (error) {
    return { error };
  }
};

const MUTATION_DELETE_TAG = gql`
  mutation deleteTag($id: String!) {
    deleteTag(id: $id)
  }
`;
const deleteTagVariables = ({ tagId }) => ({
  id: tagId
});
export const deleteTag = async ({ client, tagId }) => {
  try {
    const {
      data: { deleteTag: data }
    } = await client.mutate({
      mutation: MUTATION_DELETE_TAG,
      variables: deleteTagVariables({ tagId }),
      update: () => client.resetStore()
    });
    return { data };
  } catch (error) {
    return { error };
  }
};

export const getUserTags = async client => {
  const {
    data: {
      searchGroups: { items: groups = [] }
    },
    error
  } = await client.query({
    query: QUERY_SEARCH_GROUP_TAGS,
    fetchPolicy: "network-only"
  });

  if (error) console.error("Failed to fetch user tags", error);

  let userTags = [];
  if (groups.length) {
    userTags = groups[0].tags.reduce((accu, item) => {
      accu[item.name] = item;
      return accu;
    }, {});
  }

  return userTags;
};

const TaggingContext = React.createContext({});

export const TaggingProvider = TaggingContext.Provider;
export const TaggingConsumer = TaggingContext.Consumer;
export default TaggingContext;
