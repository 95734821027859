import React, { useRef, useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "components/snackbar";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  }
}));

export default function CheckNewVersion() {
  const isMounted = useRef(true);
  const [isNewVersion, setIsNewVersion] = useState(false);
  const isOnline = useRef(true);
  const registrationRef = useRef(null);
  const { show, types } = useSnackbar();
  const classes = useStyles();
  const onRefreshClicked = useCallback(() => {
    // Just to ensure registration.waiting is available before
    // calling postMessage()
    if (registrationRef.current && registrationRef.current.waiting) {
      registrationRef.current.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  }, []);

  const onServiceWorkerUpdated = useCallback(
    e => {
      if (e.registration) {
        registrationRef.current = e.registration;
        if (isMounted.current) {
          setIsNewVersion(true);
        }
      }
    },
    [setIsNewVersion]
  );

  const onOfflineMode = useCallback(
    e => {
      if (isOnline.current && isMounted.current) {
        isOnline.current = false;
        show({
          message: "No Internet connection",
          type: types.default
        });
      }
    },
    [isOnline, show, types.default]
  );

  const onOnlineMode = useCallback(
    e => {
      if (!isOnline.current && isMounted.current) {
        isOnline.current = true;
        show({
          message: "Internet connection restored",
          type: types.default,
          autoHide: false
        });
      }
    },
    [isOnline, show, types.default]
  );

  useEffect(() => {
    window.addEventListener("newContentAvailable", onServiceWorkerUpdated);
    window.addEventListener("offlineMode", onOfflineMode);
    window.addEventListener("onlineMode", onOnlineMode);
    return () => {
      isMounted.current = false;
      window.removeEventListener("newContentAvailable", onServiceWorkerUpdated);
      window.removeEventListener("offlineMode", onOfflineMode);
      window.removeEventListener("onlineMode", onOnlineMode);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNewVersion) {
      show({
        message: "A new version is available",
        type: types.default,
        content: (
          <Button
            onClick={onRefreshClicked}
            color="primary"
            className={classes.button}
          >
            Refresh
          </Button>
        ),
        autoHide: false
      });
      setIsNewVersion(false);
    }
  }, [
    show,
    isNewVersion,
    types.info,
    classes.button,
    onRefreshClicked,
    types.default
  ]);

  return null;
}
