import React, { memo } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  position: relative;
  max-width: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  margin-bottom: 16px;
`

function Spinner() {
  return (
    <SpinnerContainer>
      <CircularProgress />
    </SpinnerContainer>
  )
};

export default memo(Spinner);

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
`;