import React, { Component, Fragment } from "react";
import { Tooltip } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { withApollo } from "react-apollo";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { ApiProvider } from "contexts/api-context";
import { API, Auth } from "aws-amplify";
import {
  TaggingProvider,
  getUserTags,
  createTag,
  deleteTag
} from "./contexts/tagging-context";

import Routes from "./components/routes";
import { StateProvider as ListingStateProvider } from "./contexts/listings-state-context";
import ErrorPage from "./components/error-page";
import LogoutIcon from '@material-ui/icons/ExitToApp';
import MenuButton from "./components/menu-button";
import "./App.css";
import AccountCircle from '@material-ui/icons/AccountCircle';

class App extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      userTags: {},
      breadCrumbs: [],
      isError: false,
      user: null
    };
  }

  createTag = client => async ({ userTagId, entityId }) => {
    const { data, error } = await createTag({ client, userTagId, entityId });
    return { data, error };
  };

  deleteTag = client => async ({ tagId }) => {
    const { data, error } = await deleteTag({ client, tagId });
    return { data, error };
  };

  async componentDidMount() {
    this._isMounted = true;
    // TODO: Check how to reset store cache
    await this.props.client.resetStore();

    let state = { isAuthenticating: false };
    try {
      let currentSession = await Auth.currentSession();
      if (currentSession) {
        //get state for authenticated user
        let newState = await this.handleAuthentication(currentSession);
        state = {...newState, ...state};
      }
    } catch (e) {
      if (e !== "No current user") {
        console.error(e);
      }
    }

    if (this._isMounted) this.setState(state);
  }

  async apiCall({ service, path, method, init = {} }) {
    const { headers: initHeaders = {}, ...restInit } = init;
    const headers = {
      ...initHeaders,
      Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken()
    };
    return await API[method](service, path, { ...restInit, headers });
  }

  //returns updated state for authenticated user
  async handleAuthentication(currentSession){
    const { client } = this.props;
    const newState = {...this.state};

    newState.isAuthenticated = true;
    newState.userTags = await getUserTags(client);
    newState.user = currentSession.idToken.payload;

    window.sessionStorage.setItem("userId", newState.user.sub);

    return newState;
  }

  userHasAuthenticated = async (authenticated, currentSession = undefined) => {    
    if(currentSession){
      //get state for authenticated user
      if (this._isMounted) this.setState(await this.handleAuthentication(currentSession));
    }
    else{
      if (this._isMounted) this.setState({ isAuthenticated: authenticated });
    }
  };

  handleLogout = async (event) => {
    window.sessionStorage.removeItem("userId");
    await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      isError: true
    };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    const { user, userTags, isAuthenticated, isAuthenticating } = this.state;
    return (
      !isAuthenticating && (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ApiProvider value={{ API: this.apiCall }}>
            <ListingStateProvider>
              <TaggingProvider
                value={{
                  userTags,
                  createTag: this.createTag(this.props.client),
                  deleteTag: this.deleteTag(this.props.client)
                }}
              >
                <div className="App">
                  <Navbar fluid collapseOnSelect style={{ marginBottom: "0"}}>
                    <Navbar.Header >
                      <Navbar.Brand>
                        <Link to="/" className="link-logo">
                        <img
                          alt="Compit"
                          style={{
                            height: 22,
                            webkitFilter: 'invert(100%)',
                            filter: 'invert(100%)'
                          }}
                          src='https://compit-invest-media.s3.amazonaws.com/CompitLogo_Header-midnight.png'
                        />
                      </Link>
                      </Navbar.Brand>
                      <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse>
                      <Nav pullRight>
                        {isAuthenticated ? (
                          <MenuButton icon={<AccountCircle fontSize="large"/>} onHover name='profile-menu' items={[
                            { 
                              onClick: this.handleLogout, 
                              displayName: `(${user.name} ${user.family_name}) Logout`, 
                              icon: <LogoutIcon /> 
                            }
                          ]} />

                        ) : (
                            <Fragment>
                              <LinkContainer to="/signup">
                                <NavItem>Signup</NavItem>
                              </LinkContainer>
                              <LinkContainer to="/login">
                                <NavItem>Login</NavItem>
                              </LinkContainer>
                            </Fragment>
                          )}
                      </Nav>
                      <Nav pullRight>
                        {isAuthenticated && (
                          <LinkContainer to="/saved-searches">
                            <Tooltip title="Investment Queries">
                              <NavItem>IQ</NavItem>
                            </Tooltip>
                          </LinkContainer>
                        )}
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                  {this.state.isError ? (
                    <ErrorPage />
                  ) : (
                    <Routes childProps={childProps} />
                    )}
                </div>
              </TaggingProvider>
            </ListingStateProvider>
          </ApiProvider>
        </MuiPickersUtilsProvider>
      )
    );
  }
}

export default withApollo(withRouter(App));
